import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import Header from '../components/_es/Header-es';
import Footer from '../components/_es/Footer-es';
import styles from './index.module.scss';

export default class EsHomePage extends PureComponent {
  render() {
    const { data: { site } } = this.props;

    return (
      <div>
        <Helmet
          title={site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'FAO AQUASTAT' },
            { name: 'keywords', content: 'fao, water, aquastat, water production' },
          ]}
        />
        <Header siteTitle={site.siteMetadata.title} location={{}} />

        <div className="app-wrapper">
          <div className={styles.wrapper}>
          <p>
            La versión en español de este sitio web estará disponible próximamente. Nuestras apologías por esta inconveniencia.
            Mientras tanto si desea puede acceder a la <a href="http://www.fao.org/nr/water/aquastat/main/indexesp.stm">antigua versión del sitio web de AQUASTAT</a>
          </p>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

EsHomePage.propTypes = {
  data: PropTypes.object.isRequired,
};

/* eslint-disable no-undef */
export const pageQuery = graphql`
  query EsHomePageQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
