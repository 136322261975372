import React from 'react';

import styles from './styles.module.scss';

/* eslint-disable */
const FaoLogo = () => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 295.5 56.7"
    style={{height: '60px'}}
  >
	<g>
	<path className={styles.st0} d="M27.9,55.5c-14.8,0-26.8-12-26.8-26.8c0-14.8,12-26.8,26.8-26.8c14.8,0,26.8,12,26.8,26.8
		C54.7,43.5,42.7,55.5,27.9,55.5z M27.8,18.1c0,0,2.2,2.3,2.2,4.1c0,2-1.5,2.5-2.1,2.5c0,0-0.1,0-0.1,0h0c0,0-0.1,0-0.1,0
		c-0.5,0-2.1-0.5-2.1-2.5C25.6,20.4,27.8,18.1,27.8,18.1z M7.6,30.2h2.4v-4.5h4.1v-1.9h-4.1v-2.5h4.2v-1.9H7.6V30.2z M30,18.5h2.6
		l-3-10.8h-3.3l-3.1,10.8h2.4l0.7-2.4h3.1L30,18.5z M29,14.2h-2.2l1-4.7h0.2L29,14.2z M40.3,24.8c0,3.2,1.5,5.6,4.6,5.6
		c3.1,0,4.6-2.4,4.6-5.6c0-3.2-1.5-5.6-4.6-5.6C41.8,19.2,40.3,21.6,40.3,24.8z M42.9,24.8c0-2.1,0.5-3.8,2-3.8c1.5,0,2,1.7,2,3.8
		s-0.5,3.8-2,3.8C43.4,28.6,42.9,26.9,42.9,24.8z M26.8,52.5c0.1-1,0.3-2.7,0.3-4.7c0-0.5,0-0.2,0-0.3c0-2.8-0.1-3.7-1.9-4.3
		c-2.9-0.9-4-4.1-3.4-8.1l-9.5-24.3c-5,4.4-8.1,10.8-8.1,17.9C4.1,41.5,14.2,51.9,26.8,52.5z M27.9,37c-3.8-0.9-5.2-4.3-5.2-7.4
		L14.4,9.1c-0.5,0.4-1.1,0.8-1.6,1.2c1.8,4.4,10.1,23.9,10.1,23.9c1.5,3.5,4.1,2.3,4.8,5.3c0.2,0.7,0.2,1,0.2,1h0.3c0,0,0-0.3,0.2-1
		c0.6-2.9,3.3-1.7,4.8-5.3c0,0,8.3-19.6,10.1-23.9c-0.5-0.4-1-0.8-1.6-1.2l-8.3,20.5C33.1,32.7,31.7,36.2,27.9,37L27.9,37z
		 M27.9,30.9c-1.6-0.4-3.6-2-3.9-4c-0.1-0.8-0.2-1-0.1-2.6L16.7,7.7c-0.6,0.3-1.2,0.7-1.7,1c2.2,4.9,8.9,19.9,8.9,20
		c1.1,2.2,2.9,2.3,3.6,3.6c0.4,0.8,0.4,1.5,0.4,1.5H28c0-0.1,0-0.8,0.4-1.5c0.6-1.3,2.5-1.4,3.6-3.6c0,0,6.7-15,8.9-20
		c-0.6-0.4-1.1-0.7-1.7-1l-7.3,16.6c0.1,1.6,0,1.8-0.1,2.6C31.6,28.9,29.5,30.5,27.9,30.9L27.9,30.9z M28,28l0.1-0.9
		c0.5-2.1,2-1.8,3-3.9c0,0,0,0,0,0c0-0.1,5.2-11.2,7.4-15.8c-3.2-1.6-6.8-2.5-10.6-2.5c-3.8,0-7.4,0.9-10.6,2.5
		c2.2,4.6,7.3,15.7,7.4,15.8c0,0,0,0,0,0c1.1,2.1,2.6,1.8,3,3.9l0.1,0.9H28z M51.8,28.7c0-7.1-3.1-13.5-8.1-17.9l-9.5,24.3
		c0.6,4-0.5,7.1-3.4,8.1c-1.8,0.6-1.9,1.5-1.9,4.3c0,0.1,0-0.2,0,0.3c0,2,0.1,3.6,0.3,4.7C41.7,51.9,51.8,41.5,51.8,28.7z
		 M11.6,35.7c0,0,0.4,0.7,0.6,1.3c0.3,0.5,0.7,1.2,0.7,1.2l-0.6,0.4c0,0-0.5-0.8-0.9-1.7c-0.1,0.1-0.9,0.5-1,0.5
		c0.1,0.1,0.8,1.6,0.8,1.6l-0.6,0.4c0,0-0.8-1.5-0.9-1.6C9.6,37.8,8,38.6,8,38.6l-0.4-0.8L11.6,35.7L11.6,35.7z M14.1,39.8l0.6,0.7
		l-3.5,2.9l-0.6-0.7L14.1,39.8L14.1,39.8z M17.4,42.9l0.7,0.5l-0.9,4.8l-0.8-0.5c0,0,0.2-1,0.2-1.1c0,0-0.5-0.3-0.9-0.6
		c-0.5-0.3-0.9-0.7-0.9-0.7c-0.1,0.1-1,0.6-1,0.6l-0.7-0.6L17.4,42.9L17.4,42.9z M15.4,44.9c0.1,0.1,0.4,0.3,0.7,0.5
		c0.3,0.2,0.6,0.4,0.7,0.5c0-0.2,0.4-1.8,0.4-2.1C16.9,44,15.5,44.8,15.4,44.9z M20.2,44.6c0,0,0.7,0.4,1.8,0.7
		c0.7,0.2,1.3,0.4,1.9,0.6l-0.2,0.7c0,0-0.6-0.1-1.5-0.4c-0.1,0.2-1.2,3.6-1.2,3.6l-0.8-0.3c0,0,1.2-3.4,1.2-3.6
		c-0.9-0.3-1.5-0.6-1.5-0.6L20.2,44.6z M34.6,45.6c0.3,0.2,0.5,0.4,0.6,0.8c0.1,0.2,0.1,0.3,0.1,0.5c0,0.6-0.4,1.1-1.2,1.4
		c0,0-0.4,0.1-0.5,0.2c0,0.1,0.5,1.7,0.5,1.7l-0.8,0.2l-1.3-4.4l1.2-0.4C33.7,45.4,34.2,45.4,34.6,45.6z M32.9,46.4
		c0,0.1,0.4,1.2,0.4,1.3c0.2,0,0.5-0.2,0.5-0.2c0.1,0,0.4-0.1,0.5-0.4c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.2
		c-0.1-0.2-0.1-0.3-0.3-0.4c-0.2-0.1-0.6-0.1-0.8,0C33.3,46.2,33,46.3,32.9,46.4z M37.3,43.7l3.9,2.9L40.5,47c0,0-0.8-0.6-0.9-0.7
		c0,0-0.5,0.3-0.9,0.6c-0.4,0.2-0.9,0.5-0.9,0.5c0,0.1,0.2,1.1,0.2,1.1l-0.8,0.4L36.6,44L37.3,43.7z M37.7,46.7
		c0.1-0.1,0.4-0.2,0.7-0.4c0.3-0.2,0.5-0.3,0.6-0.4c-0.2-0.1-1.4-1.1-1.6-1.3C37.4,45,37.7,46.5,37.7,46.7z M41.8,39.7l3.4,3.1
		l-0.6,0.7c0,0-3.2-0.9-3.7-1.1c0.4,0.3,2.4,2.5,2.4,2.5l-0.6,0.5l-3.2-3.3l0.8-0.7c0,0,3.1,0.9,3.6,1.1c-0.4-0.3-2.5-2.2-2.5-2.2
		L41.8,39.7z M43.3,37.4l4,2.3l-0.4,0.7l-4-2.3L43.3,37.4z M45,33.6l0.7,0.4L45.7,34c-0.2,0.3-0.3,0.4-0.4,0.7
		c-0.1,0.1-0.1,0.4,0,0.6c0.1,0.1,0.1,0.2,0.3,0.2c0.3,0.1,0.6-0.2,0.9-0.5c0.4-0.4,0.9-1,1.8-0.7c0.9,0.3,1.1,1,0.8,2.1
		c-0.1,0.3-0.3,0.6-0.5,0.8l0,0.1l-0.7-0.3l0.1-0.1c0.1-0.1,0.4-0.4,0.5-0.8c0.1-0.3,0.1-0.8-0.4-1c-0.4-0.1-0.7,0.2-1,0.5
		c-0.4,0.4-0.9,0.9-1.6,0.6c-0.3-0.1-0.6-0.3-0.7-0.6c-0.2-0.3-0.2-0.8,0-1.2C44.6,34.2,44.8,33.9,45,33.6L45,33.6z"/>
</g>
<g>
	<path className={styles.st0} d="M69,19.6c2.2,0,4.2,1.6,4.2,5.4c0,3.8-2,5.4-4.2,5.4c-2.2,0-4.2-1.6-4.2-5.4C64.8,21.3,66.8,19.6,69,19.6z
		 M69,29c1.2,0,2.2-1,2.2-3.9s-1-3.9-2.2-3.9c-1.2,0-2.2,1-2.2,3.9S67.8,29,69,29z"/>
	<path className={styles.st0} d="M74.8,23.8c0-0.5,0-0.9-0.1-1.3h1.7c0,0.5,0.1,1,0.1,1.4h0c0.2-0.6,0.8-1.5,2-1.5c0.1,0,0.2,0,0.3,0v1.8
		c-0.2,0-0.3-0.1-0.5-0.1c-0.8,0-1.6,0.5-1.6,1.9v4.3h-1.9V23.8z"/>
	<path className={styles.st0} d="M80.2,31.5c0.4,0.2,1.3,0.7,2.3,0.7c1.8,0,2.1-1.2,2.1-2.4V29h0c-0.3,0.6-0.8,1.3-2.1,1.3
		c-1.1,0-2.7-0.7-2.7-3.9c0-2.1,0.8-4,2.8-4c1.1,0,1.7,0.7,2.1,1.3h0.1c0-0.4,0-0.8,0.1-1.2h1.7c0,0.4-0.1,0.8-0.1,1.2v5.7
		c0,2.4-0.7,4.2-3.6,4.2c-1.2,0-2.2-0.3-2.7-0.5L80.2,31.5z M82.9,29c1.2,0,1.6-0.8,1.6-2.6c0-1.6-0.6-2.5-1.5-2.5
		c-1,0-1.4,0.9-1.4,2.5C81.6,28.2,82.2,29,82.9,29z"/>
	<path className={styles.st0} d="M88.4,23c0.5-0.2,1.3-0.6,2.5-0.6c2.4,0,3,1.2,3,3.1V29c0,0.6,0.1,1.1,0.1,1.4h-1.7c-0.1-0.3-0.1-0.7-0.1-1.1
		h0c-0.5,0.6-1.1,1.2-2.2,1.2c-1.2,0-2.3-0.8-2.3-2.3c0-2.1,1.7-2.6,4.1-2.6h0.4v-0.3c0-0.8-0.4-1.5-1.5-1.5c-0.9,0-1.8,0.5-2.1,0.8
		L88.4,23z M92.1,26.5h-0.2c-1.6,0-2.6,0.3-2.6,1.5c0,0.7,0.5,1.2,1.2,1.2c1.1,0,1.5-0.8,1.5-2.2V26.5z"/>
	<path className={styles.st0} d="M95.6,23.8c0-0.5,0-0.9-0.1-1.3h1.8c0,0.4,0.1,0.8,0.1,1.2h0c0.3-0.5,0.8-1.3,2.1-1.3c1.8,0,2.3,1.3,2.3,2.9v5
		h-1.9v-4.7c0-1.2-0.3-1.7-1.1-1.7c-1,0-1.3,0.8-1.3,1.8v4.5h-1.9V23.8z"/>
	<path className={styles.st0} d="M103.6,19.2h1.9v1.8h-1.9V19.2z M103.6,22.6h1.9v7.8h-1.9V22.6z"/>
	<path className={styles.st0} d="M106.9,28.7l3.3-4.7H107v-1.4h5.1v1.6l-3.3,4.8h3.4v1.4h-5.3V28.7z"/>
	<path className={styles.st0} d="M113.9,23c0.5-0.2,1.3-0.6,2.5-0.6c2.4,0,3,1.2,3,3.1V29c0,0.6,0.1,1.1,0.1,1.4h-1.7c-0.1-0.3-0.1-0.7-0.1-1.1
		h0c-0.5,0.6-1.1,1.2-2.2,1.2c-1.2,0-2.3-0.8-2.3-2.3c0-2.1,1.7-2.6,4.1-2.6h0.4v-0.3c0-0.8-0.4-1.5-1.5-1.5c-0.9,0-1.8,0.5-2.1,0.8
		L113.9,23z M117.6,26.5h-0.2c-1.6,0-2.6,0.3-2.6,1.5c0,0.7,0.5,1.2,1.2,1.2c1.1,0,1.5-0.8,1.5-2.2V26.5z"/>
	<path className={styles.st0} d="M126.3,30.1c-0.4,0.2-1.1,0.3-1.8,0.3c-2.6,0-3.7-1.8-3.7-4c0-2.2,1.1-4,3.8-4c0.6,0,1.3,0.2,1.6,0.3l-0.1,1.5
		c-0.3-0.2-0.8-0.4-1.3-0.4c-1.4,0-2,1-2,2.6c0,1.5,0.7,2.6,2.1,2.6c0.5,0,1.1-0.2,1.3-0.4L126.3,30.1z"/>
	<path className={styles.st0} d="M127.6,19.2h1.9v1.8h-1.9V19.2z M127.7,22.6h1.9v7.8h-1.9V22.6z"/>
	<path className={styles.st0} d="M134.6,22.4c1.6,0,3.4,0.8,3.4,4c0,3.3-1.8,4-3.4,4c-1.6,0-3.4-0.8-3.4-4C131.2,23.3,133.1,22.4,134.6,22.4z
		 M134.6,29.1c1.2,0,1.5-1.4,1.5-2.7c0-1.3-0.3-2.7-1.5-2.7c-1.2,0-1.5,1.4-1.5,2.7C133.1,27.7,133.4,29.1,134.6,29.1z M134.7,19.5
		h1.8l-1.5,2.2h-1.2L134.7,19.5z"/>
	<path className={styles.st0} d="M139.5,23.8c0-0.5,0-0.9-0.1-1.3h1.8c0,0.4,0.1,0.8,0.1,1.2h0c0.3-0.5,0.8-1.3,2.1-1.3c1.8,0,2.3,1.3,2.3,2.9
		v5h-1.9v-4.7c0-1.2-0.3-1.7-1.1-1.7c-1,0-1.3,0.8-1.3,1.8v4.5h-1.9V23.8z"/>
	<path className={styles.st0} d="M157.7,19v10c0,0.6,0,1,0.1,1.3H156c0-0.2-0.1-0.7-0.1-1.2h0c-0.3,0.7-0.9,1.3-2.1,1.3c-1.9,0-2.7-1.8-2.7-4
		c0-2.5,1-4,2.7-4c1.1,0,1.7,0.6,2,1.2h0V19H157.7z M154.3,29.1c1.1,0,1.5-1.2,1.5-2.7c0-1.5-0.4-2.6-1.5-2.6c-1,0-1.4,1-1.4,2.6
		C153,28.2,153.3,29.1,154.3,29.1z"/>
	<path className={styles.st0} d="M165.1,30c-0.4,0.2-1.2,0.5-2.3,0.5c-2.5,0-3.6-1.8-3.6-3.9c0-2.3,1.2-4.1,3.2-4.1c1.7,0,3.1,1,3.1,4.2v0.4
		H161c0,1.3,0.5,2.1,2,2.1c1.1,0,1.7-0.3,2-0.6L165.1,30z M163.7,25.8c0-1.4-0.5-2.1-1.3-2.1c-1,0-1.4,1-1.4,2.1H163.7z"/>
	<path className={styles.st0} d="M171.1,19h1.9v11.3h-1.9V19z"/>
	<path className={styles.st0} d="M175.2,23c0.5-0.2,1.3-0.6,2.5-0.6c2.4,0,3,1.2,3,3.1V29c0,0.6,0.1,1.1,0.1,1.4h-1.7c-0.1-0.3-0.1-0.7-0.1-1.1
		h0c-0.5,0.6-1.1,1.2-2.2,1.2c-1.2,0-2.3-0.8-2.3-2.3c0-2.1,1.7-2.6,4.1-2.6h0.4v-0.3c0-0.8-0.4-1.5-1.5-1.5c-0.9,0-1.8,0.5-2.1,0.8
		L175.2,23z M178.9,26.5h-0.2c-1.6,0-2.6,0.3-2.6,1.5c0,0.7,0.5,1.2,1.2,1.2c1.1,0,1.5-0.8,1.5-2.2V26.5z"/>
	<path className={styles.st0} d="M182.2,28.5c0.3,0.1,1.1,0.5,2,0.5c0.7,0,1.2-0.2,1.2-0.8c0-0.3-0.2-0.6-0.8-1l-0.8-0.5
		c-0.8-0.5-1.6-1-1.6-2.2c0-1.1,1-2.2,2.7-2.2c1,0,1.8,0.3,2,0.3l-0.1,1.5c-0.5-0.2-1-0.3-1.6-0.3c-0.8,0-1.1,0.3-1.1,0.8
		c0,0.4,0.3,0.7,0.9,1l0.9,0.6c1,0.6,1.5,1.1,1.5,2c0,1.4-1.2,2.3-2.9,2.3c-1,0-1.8-0.2-2.2-0.4L182.2,28.5z"/>
	<path className={styles.st0} d="M192.7,19.8h2.3l3.5,8.3h0v-8.3h1.8v10.5H198l-3.5-8.2h0v8.2h-1.8V19.8z"/>
	<path className={styles.st0} d="M202.6,23c0.5-0.2,1.3-0.6,2.5-0.6c2.4,0,3,1.2,3,3.1V29c0,0.6,0.1,1.1,0.1,1.4h-1.7c-0.1-0.3-0.1-0.7-0.1-1.1
		h0c-0.5,0.6-1.1,1.2-2.2,1.2c-1.2,0-2.3-0.8-2.3-2.3c0-2.1,1.7-2.6,4.1-2.6h0.4v-0.3c0-0.8-0.4-1.5-1.5-1.5c-0.9,0-1.8,0.5-2.1,0.8
		L202.6,23z M206.3,26.5h-0.2c-1.6,0-2.6,0.3-2.6,1.5c0,0.7,0.5,1.2,1.2,1.2c1.1,0,1.5-0.8,1.5-2.2V26.5z"/>
	<path className={styles.st0} d="M215.1,30.1c-0.4,0.2-1.1,0.3-1.8,0.3c-2.6,0-3.7-1.8-3.7-4c0-2.2,1.1-4,3.8-4c0.6,0,1.3,0.2,1.6,0.3l-0.1,1.5
		c-0.3-0.2-0.8-0.4-1.3-0.4c-1.4,0-2,1-2,2.6c0,1.5,0.7,2.6,2.1,2.6c0.5,0,1.1-0.2,1.3-0.4L215.1,30.1z"/>
	<path className={styles.st0} d="M216.4,19.2h1.9v1.8h-1.9V19.2z M216.4,22.6h1.9v7.8h-1.9V22.6z"/>
	<path className={styles.st0} d="M223.4,22.4c1.6,0,3.4,0.8,3.4,4c0,3.3-1.8,4-3.4,4c-1.6,0-3.4-0.8-3.4-4C219.9,23.3,221.8,22.4,223.4,22.4z
		 M223.4,29.1c1.2,0,1.5-1.4,1.5-2.7c0-1.3-0.3-2.7-1.5-2.7c-1.2,0-1.5,1.4-1.5,2.7C221.9,27.7,222.2,29.1,223.4,29.1z"/>
	<path className={styles.st0} d="M228.3,23.8c0-0.5,0-0.9-0.1-1.3h1.8c0,0.4,0.1,0.8,0.1,1.2h0c0.3-0.5,0.8-1.3,2.1-1.3c1.8,0,2.3,1.3,2.3,2.9
		v5h-1.9v-4.7c0-1.2-0.3-1.7-1.1-1.7c-1,0-1.3,0.8-1.3,1.8v4.5h-1.9V23.8z"/>
	<path className={styles.st0} d="M241.8,30c-0.4,0.2-1.2,0.5-2.3,0.5c-2.5,0-3.6-1.8-3.6-3.9c0-2.3,1.2-4.1,3.2-4.1c1.7,0,3.1,1,3.1,4.2v0.4
		h-4.5c0,1.3,0.5,2.1,2,2.1c1.1,0,1.7-0.3,2-0.6L241.8,30z M240.4,25.8c0-1.4-0.5-2.1-1.3-2.1c-1,0-1.4,1-1.4,2.1H240.4z"/>
	<path className={styles.st0} d="M243.5,28.5c0.3,0.1,1.1,0.5,2,0.5c0.7,0,1.2-0.2,1.2-0.8c0-0.3-0.2-0.6-0.8-1l-0.8-0.5
		c-0.8-0.5-1.6-1-1.6-2.2c0-1.1,1-2.2,2.7-2.2c1,0,1.8,0.3,2,0.3l-0.1,1.5c-0.5-0.2-1-0.3-1.6-0.3c-0.8,0-1.1,0.3-1.1,0.8
		c0,0.4,0.3,0.7,0.9,1l0.9,0.6c1,0.6,1.5,1.1,1.5,2c0,1.4-1.2,2.3-2.9,2.3c-1,0-1.8-0.2-2.2-0.4L243.5,28.5z"/>
	<path className={styles.st0} d="M255.9,19.8v6.8c0,1.8,0.5,2.3,1.7,2.3c1.1,0,1.6-0.6,1.6-2.3v-6.8h1.9v6.8c0,2.8-1.6,3.9-3.6,3.9
		c-2,0-3.6-1.1-3.6-3.9v-6.8H255.9z"/>
	<path className={styles.st0} d="M263,23.8c0-0.5,0-0.9-0.1-1.3h1.8c0,0.4,0.1,0.8,0.1,1.2h0c0.3-0.5,0.8-1.3,2.1-1.3c1.8,0,2.3,1.3,2.3,2.9v5
		h-1.9v-4.7c0-1.2-0.3-1.7-1.1-1.7c-1,0-1.3,0.8-1.3,1.8v4.5H263V23.8z"/>
	<path className={styles.st0} d="M271,19.2h1.9v1.8H271V19.2z M271,22.6h1.9v7.8H271V22.6z"/>
	<path className={styles.st0} d="M281.1,19v10c0,0.6,0,1,0.1,1.3h-1.8c0-0.2-0.1-0.7-0.1-1.2h0c-0.3,0.7-0.9,1.3-2.1,1.3c-1.9,0-2.7-1.8-2.7-4
		c0-2.5,1-4,2.7-4c1.1,0,1.7,0.6,2,1.2h0V19H281.1z M277.8,29.1c1.1,0,1.5-1.2,1.5-2.7c0-1.5-0.4-2.6-1.5-2.6c-1,0-1.4,1-1.4,2.6
		C276.4,28.2,276.7,29.1,277.8,29.1z"/>
	<path className={styles.st0} d="M283.2,23c0.5-0.2,1.3-0.6,2.5-0.6c2.4,0,3,1.2,3,3.1V29c0,0.6,0.1,1.1,0.1,1.4h-1.7c-0.1-0.3-0.1-0.7-0.1-1.1
		h0c-0.5,0.6-1.1,1.2-2.2,1.2c-1.2,0-2.3-0.8-2.3-2.3c0-2.1,1.7-2.6,4.1-2.6h0.4v-0.3c0-0.8-0.4-1.5-1.5-1.5c-0.9,0-1.8,0.5-2.1,0.8
		L283.2,23z M286.9,26.5h-0.2c-1.6,0-2.6,0.3-2.6,1.5c0,0.7,0.5,1.2,1.2,1.2c1.1,0,1.5-0.8,1.5-2.2V26.5z"/>
	<path className={styles.st0} d="M290.2,28.5c0.3,0.1,1.1,0.5,2,0.5c0.7,0,1.2-0.2,1.2-0.8c0-0.3-0.2-0.6-0.8-1l-0.8-0.5
		c-0.8-0.5-1.6-1-1.6-2.2c0-1.1,1-2.2,2.7-2.2c1,0,1.8,0.3,1.9,0.3l-0.1,1.5c-0.5-0.2-1-0.3-1.6-0.3c-0.8,0-1.1,0.3-1.1,0.8
		c0,0.4,0.3,0.7,0.9,1l0.9,0.6c1,0.6,1.5,1.1,1.5,2c0,1.4-1.2,2.3-2.9,2.3c-1,0-1.8-0.2-2.2-0.4L290.2,28.5z"/>
	<path className={styles.st0} d="M65,41c0-0.6,0-1-0.1-1.3h1.8c0,0.2,0.1,0.7,0.1,1.2h0c0.3-0.7,0.9-1.3,2.1-1.3c1.9,0,2.7,1.8,2.7,4
		c0,2.5-1,4-2.7,4c-1.1,0-1.7-0.6-2-1.2h0v4.4H65V41z M68.3,46.2c1,0,1.4-1,1.4-2.6c0-1.8-0.3-2.7-1.4-2.7c-1.1,0-1.5,1.2-1.5,2.7
		C66.9,45.1,67.3,46.2,68.3,46.2z"/>
	<path className={styles.st0} d="M73.5,40.1c0.5-0.2,1.3-0.6,2.5-0.6c2.4,0,3,1.2,3,3.1V46c0,0.6,0.1,1.1,0.1,1.4h-1.7
		c-0.1-0.3-0.1-0.7-0.1-1.1h0c-0.5,0.6-1.1,1.2-2.2,1.2c-1.2,0-2.3-0.8-2.3-2.3c0-2.1,1.7-2.6,4.1-2.6h0.4v-0.3
		c0-0.8-0.4-1.5-1.5-1.5c-0.9,0-1.8,0.5-2.1,0.8L73.5,40.1z M77.1,43.6H77c-1.6,0-2.6,0.3-2.6,1.5c0,0.7,0.5,1.2,1.2,1.2
		c1.1,0,1.5-0.8,1.5-2.2V43.6z"/>
	<path className={styles.st0} d="M80.7,40.9c0-0.5,0-0.9-0.1-1.3h1.7c0,0.5,0.1,1,0.1,1.4h0c0.2-0.6,0.8-1.5,2-1.5c0.1,0,0.2,0,0.3,0v1.8
		c-0.2,0-0.3-0.1-0.5-0.1c-0.8,0-1.6,0.5-1.6,1.9v4.3h-1.9V40.9z"/>
	<path className={styles.st0} d="M86.2,40.1c0.5-0.2,1.3-0.6,2.5-0.6c2.4,0,3,1.2,3,3.1V46c0,0.6,0.1,1.1,0.1,1.4H90c-0.1-0.3-0.1-0.7-0.1-1.1
		h0c-0.5,0.6-1.1,1.2-2.2,1.2c-1.2,0-2.3-0.8-2.3-2.3c0-2.1,1.7-2.6,4.1-2.6h0.4v-0.3c0-0.8-0.4-1.5-1.5-1.5c-0.9,0-1.8,0.5-2.1,0.8
		L86.2,40.1z M89.9,43.6h-0.2c-1.6,0-2.6,0.3-2.6,1.5c0,0.7,0.5,1.2,1.2,1.2c1.1,0,1.5-0.8,1.5-2.2V43.6z"/>
	<path className={styles.st0} d="M97.5,36.1h1.9v11.3h-1.9V36.1z"/>
	<path className={styles.st0} d="M101.6,40.1c0.5-0.2,1.3-0.6,2.5-0.6c2.4,0,3,1.2,3,3.1V46c0,0.6,0.1,1.1,0.1,1.4h-1.7
		c-0.1-0.3-0.1-0.7-0.1-1.1h0c-0.5,0.6-1.1,1.2-2.2,1.2c-1.2,0-2.3-0.8-2.3-2.3c0-2.1,1.7-2.6,4.1-2.6h0.4v-0.3
		c0-0.8-0.4-1.5-1.5-1.5c-0.9,0-1.8,0.5-2.1,0.8L101.6,40.1z M105.3,43.6h-0.2c-1.6,0-2.6,0.3-2.6,1.5c0,0.7,0.5,1.2,1.2,1.2
		c1.1,0,1.5-0.8,1.5-2.2V43.6z"/>
	<path className={styles.st0} d="M117.4,44.9h-3.6l-0.8,2.5h-1.9l3.5-10.5h2.1l3.4,10.5h-2L117.4,44.9z M117,43.4l-1.3-4.9h0l-1.5,4.9H117z"/>
	<path className={styles.st0} d="M121.3,36.1h1.9v11.3h-1.9V36.1z"/>
	<path className={styles.st0} d="M125.2,36.3h1.9v1.8h-1.9V36.3z M125.2,39.6h1.9v7.8h-1.9V39.6z"/>
	<path className={styles.st0} d="M128.9,40.9c0-0.6,0-1-0.1-1.3h1.9c0,0.4,0.1,0.8,0.1,1.2h0c0.3-0.6,0.9-1.3,2-1.3c1.3,0,1.7,0.8,2,1.2
		c0.4-0.5,0.9-1.2,2.1-1.2c1.7,0,2.2,1.2,2.2,3.1v4.8h-1.9v-4.9c0-1-0.3-1.5-1-1.5c-0.8,0-1.3,0.8-1.3,1.6v4.7H133v-4.9
		c0-1-0.3-1.5-1-1.5c-0.8,0-1.3,0.8-1.3,1.6v4.7h-1.9V40.9z"/>
	<path className={styles.st0} d="M146.4,47c-0.4,0.2-1.2,0.5-2.3,0.5c-2.5,0-3.6-1.8-3.6-3.9c0-2.3,1.2-4.1,3.2-4.1c1.7,0,3.1,1,3.1,4.2V44
		h-4.5c0,1.3,0.5,2.1,2,2.1c1.1,0,1.7-0.3,2-0.6L146.4,47z M145,42.8c0-1.4-0.5-2.1-1.3-2.1c-1,0-1.4,1-1.4,2.1H145z"/>
	<path className={styles.st0} d="M148.3,40.9c0-0.5,0-0.9-0.1-1.3h1.8c0,0.4,0.1,0.8,0.1,1.2h0c0.3-0.5,0.8-1.3,2.1-1.3c1.8,0,2.3,1.3,2.3,2.9
		v5h-1.9v-4.7c0-1.2-0.3-1.7-1.1-1.7c-1,0-1.3,0.8-1.3,1.8v4.5h-1.9V40.9z"/>
	<path className={styles.st0} d="M156.8,38l1.9-0.6v2.2h1.6V41h-1.6v4c0,0.8,0.3,1.1,0.8,1.1c0.3,0,0.6-0.1,0.7-0.2v1.3
		c-0.3,0.1-0.7,0.3-1.3,0.3c-1.4,0-2.2-0.6-2.2-2.3V41h-1.2v-1.4h1.2V38z"/>
	<path className={styles.st0} d="M161.8,40.1c0.5-0.2,1.3-0.6,2.5-0.6c2.4,0,3,1.2,3,3.1V46c0,0.6,0.1,1.1,0.1,1.4h-1.7
		c-0.1-0.3-0.1-0.7-0.1-1.1h0c-0.5,0.6-1.1,1.2-2.2,1.2c-1.2,0-2.3-0.8-2.3-2.3c0-2.1,1.7-2.6,4.1-2.6h0.4v-0.3
		c0-0.8-0.4-1.5-1.5-1.5c-0.9,0-1.8,0.5-2.1,0.8L161.8,40.1z M165.5,43.6h-0.2c-1.6,0-2.6,0.3-2.6,1.5c0,0.7,0.5,1.2,1.2,1.2
		c1.1,0,1.5-0.8,1.5-2.2V43.6z"/>
	<path className={styles.st0} d="M174.3,47.2c-0.4,0.2-1.1,0.3-1.8,0.3c-2.6,0-3.7-1.8-3.7-4c0-2.2,1.1-4,3.8-4c0.6,0,1.3,0.2,1.6,0.3l-0.1,1.5
		c-0.3-0.2-0.8-0.4-1.3-0.4c-1.4,0-2,1-2,2.6c0,1.5,0.7,2.6,2.1,2.6c0.5,0,1.1-0.2,1.3-0.4L174.3,47.2z"/>
	<path className={styles.st0} d="M175.5,36.3h1.9v1.8h-1.9V36.3z M175.6,39.6h1.9v7.8h-1.9V39.6z"/>
	<path className={styles.st0} d="M182.5,39.5c1.6,0,3.4,0.8,3.4,4c0,3.3-1.8,4-3.4,4c-1.6,0-3.4-0.8-3.4-4C179.1,40.3,181,39.5,182.5,39.5z
		 M182.5,46.2c1.2,0,1.5-1.4,1.5-2.7c0-1.3-0.3-2.7-1.5-2.7c-1.2,0-1.5,1.4-1.5,2.7C181,44.8,181.3,46.2,182.5,46.2z M182.6,36.6
		h1.8l-1.5,2.2h-1.2L182.6,36.6z"/>
	<path className={styles.st0} d="M187.4,40.9c0-0.5,0-0.9-0.1-1.3h1.8c0,0.4,0.1,0.8,0.1,1.2h0c0.3-0.5,0.8-1.3,2.1-1.3c1.8,0,2.3,1.3,2.3,2.9
		v5h-1.9v-4.7c0-1.2-0.3-1.7-1.1-1.7c-1,0-1.3,0.8-1.3,1.8v4.5h-1.9V40.9z"/>
	<path className={styles.st0} d="M201.9,45.5L201.9,45.5l1.5-5.9h1.8l-2.8,9.3c-0.3,1.1-1,1.9-2.2,1.9c-0.5,0-0.9-0.1-1.2-0.2l0.1-1.4
		c0.2,0.1,0.4,0.2,0.7,0.2c0.6,0,0.8-0.3,1-1.1l0.1-0.7l-2.6-7.9h1.9L201.9,45.5z"/>
	<path className={styles.st0} d="M210.3,36.1h1.9v11.3h-1.9V36.1z"/>
	<path className={styles.st0} d="M214.4,40.1c0.5-0.2,1.3-0.6,2.5-0.6c2.4,0,3,1.2,3,3.1V46c0,0.6,0.1,1.1,0.1,1.4h-1.7
		c-0.1-0.3-0.1-0.7-0.1-1.1h0c-0.5,0.6-1.1,1.2-2.2,1.2c-1.2,0-2.3-0.8-2.3-2.3c0-2.1,1.7-2.6,4.1-2.6h0.4v-0.3
		c0-0.8-0.4-1.5-1.5-1.5c-0.9,0-1.8,0.5-2.1,0.8L214.4,40.1z M218.1,43.6H218c-1.6,0-2.6,0.3-2.6,1.5c0,0.7,0.5,1.2,1.2,1.2
		c1.1,0,1.5-0.8,1.5-2.2V43.6z"/>
	<path className={styles.st0} d="M230.3,44.9h-3.6l-0.8,2.5h-1.9l3.5-10.5h2.1l3.4,10.5h-2L230.3,44.9z M229.9,43.4l-1.3-4.9h0l-1.5,4.9H229.9z
		"/>
	<path className={styles.st0} d="M234.2,48.6c0.4,0.2,1.3,0.7,2.3,0.7c1.8,0,2.1-1.2,2.1-2.4v-0.7h0c-0.3,0.6-0.8,1.3-2.1,1.3
		c-1.1,0-2.7-0.7-2.7-3.9c0-2.1,0.8-4,2.8-4c1.1,0,1.7,0.7,2.1,1.3h0.1c0-0.4,0-0.8,0.1-1.2h1.7c0,0.4-0.1,0.8-0.1,1.2v5.7
		c0,2.4-0.7,4.2-3.6,4.2c-1.2,0-2.2-0.3-2.7-0.5L234.2,48.6z M236.9,46c1.2,0,1.6-0.8,1.6-2.6c0-1.6-0.6-2.5-1.5-2.5
		c-1,0-1.4,0.9-1.4,2.5C235.7,45.3,236.2,46,236.9,46z"/>
	<path className={styles.st0} d="M242.1,40.9c0-0.5,0-0.9-0.1-1.3h1.7c0,0.5,0.1,1,0.1,1.4h0c0.2-0.6,0.8-1.5,2-1.5c0.1,0,0.2,0,0.3,0v1.8
		c-0.2,0-0.3-0.1-0.5-0.1c-0.8,0-1.6,0.5-1.6,1.9v4.3h-1.9V40.9z"/>
	<path className={styles.st0} d="M247.5,36.3h1.9v1.8h-1.9V36.3z M247.5,39.6h1.9v7.8h-1.9V39.6z"/>
	<path className={styles.st0} d="M256.6,47.2c-0.4,0.2-1.1,0.3-1.8,0.3c-2.6,0-3.7-1.8-3.7-4c0-2.2,1.1-4,3.8-4c0.6,0,1.3,0.2,1.6,0.3l-0.1,1.5
		c-0.3-0.2-0.8-0.4-1.3-0.4c-1.4,0-2,1-2,2.6c0,1.5,0.7,2.6,2.1,2.6c0.5,0,1.1-0.2,1.3-0.4L256.6,47.2z"/>
	<path className={styles.st0} d="M263.9,46.1c0,0.5,0,0.9,0.1,1.3h-1.8c0-0.4-0.1-0.8-0.1-1.2l0,0l0,0c-0.4,0.6-0.8,1.3-2.1,1.3
		c-1.8,0-2.3-1.3-2.3-2.9v-5h1.9v4.7c0,1.2,0.3,1.7,1.1,1.7c1,0,1.3-0.9,1.3-1.9v-4.5h1.9V46.1z"/>
	<path className={styles.st0} d="M265.7,36.1h1.9v11.3h-1.9V36.1z"/>
	<path className={styles.st0} d="M270.2,38l1.9-0.6v2.2h1.6V41h-1.6v4c0,0.8,0.3,1.1,0.8,1.1c0.3,0,0.6-0.1,0.7-0.2v1.3
		c-0.3,0.1-0.7,0.3-1.3,0.3c-1.4,0-2.2-0.6-2.2-2.3V41H269v-1.4h1.2V38z"/>
	<path className={styles.st0} d="M281,46.1c0,0.5,0,0.9,0.1,1.3h-1.8c0-0.4-0.1-0.8-0.1-1.2l0,0l0,0c-0.4,0.6-0.8,1.3-2.1,1.3
		c-1.8,0-2.3-1.3-2.3-2.9v-5h1.9v4.7c0,1.2,0.3,1.7,1.1,1.7c1,0,1.3-0.9,1.3-1.9v-4.5h1.9V46.1z"/>
	<path className={styles.st0} d="M282.7,40.9c0-0.5,0-0.9-0.1-1.3h1.7c0,0.5,0.1,1,0.1,1.4h0c0.2-0.6,0.8-1.5,2-1.5c0.1,0,0.2,0,0.3,0v1.8
		c-0.2,0-0.3-0.1-0.5-0.1c-0.8,0-1.6,0.5-1.6,1.9v4.3h-1.9V40.9z"/>
	<path className={styles.st0} d="M288.2,40.1c0.5-0.2,1.3-0.6,2.5-0.6c2.4,0,3,1.2,3,3.1V46c0,0.6,0.1,1.1,0.1,1.4H292
		c-0.1-0.3-0.1-0.7-0.1-1.1h0c-0.5,0.6-1.1,1.2-2.2,1.2c-1.2,0-2.3-0.8-2.3-2.3c0-2.1,1.7-2.6,4.1-2.6h0.4v-0.3
		c0-0.8-0.4-1.5-1.5-1.5c-0.9,0-1.8,0.5-2.1,0.8L288.2,40.1z M291.9,43.6h-0.2c-1.6,0-2.6,0.3-2.6,1.5c0,0.7,0.5,1.2,1.2,1.2
		c1.1,0,1.5-0.8,1.5-2.2V43.6z"/>
</g>
  </svg>
);

export default FaoLogo;
