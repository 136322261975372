import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

import FaoLogo from '../FaoLogo-es';

import styles from './styles.module.scss';

const HEADER_LINKS = [
  {
    link: 'http://www.fao.org/about/es/',
    label: 'Acerca de',
  },
  {
    link: 'http://www.fao.org/in-action/es/',
    label: 'En acción',
  },
  {
    link: 'http://www.fao.org/countryprofiles/es/',
    label: 'Países',
  },
  {
    link: 'http://www.fao.org/themes/es/',
    label: 'Temas',
  },
  {
    link: 'http://www.fao.org/news/es/',
    label: 'Medios',
  },
  {
    link: 'http://www.fao.org/publications/es/',
    label: 'Publicaciones',
  },
  {
    link: 'http://www.fao.org/statistics/es/',
    label: 'Estadísticas',
  },
  {
    link: 'http://www.fao.org/partnerships/es/',
    label: 'Asociaciones',
  },
];

const Header = ({ location }) => (
  <React.Fragment>
    <div className={styles.header}>
      <div className={styles.contentWrapper}>
        <a
          href="http://www.fao.org/"
          className={styles.logo}
        >
          <FaoLogo />
        </a>

        <div className={styles.rightContent}>
          <form action="http://www.fao.org/search/es/" method="get" target="_self" className={styles.searchForm}>
            <input name="q" type="text" className={styles.searchInput} size="18" required placeholder="Busqueda personalizada" />
            <input name="siteurl" type="hidden" value={location.pathname} />
            <button className={styles.searchButton} type="submit">Enviar</button>
          </form>

          <div className={styles.links}>
            {HEADER_LINKS.map(({ link, label }) => (
              <a key={label} href={link} className={styles.topLink} alt={label}>{label}</a>
            ))}
          </div>
        </div>
      </div>
    </div>
    <div className={styles.langPickerWrapper}>
      <ul className={styles.langPicker}>
        {/* TODO: Remove this monster and redirect to relevant translated websites when they're completed */}
        {(typeof window !== 'undefined') && [['', 'English'], ['fr', 'Français'], ['es', 'Español']].map(([code, lang]) => {
          const path = window.location.pathname;
          if (code !== '' && (path.startsWith(`/${code}`) || path.startsWith(`/aquastat/${code}`))) {
            return <li key={lang}>{lang}</li>;
          }
          {/* if (code === '' && (!path.startsWith('/es') || !path.startsWith('/aquastat/es')) && (!path.startsWith('/fr') || !path.startsWith('/aquastat/fr'))) {
            return <li key={lang}>{lang}</li>;
          } */}

          return <li key={lang}><Link to={`/${code}`}>{lang}</Link></li>;
        })}
      </ul>
    </div>
  </React.Fragment>
);

Header.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Header;
